<template>
  <div class="modal text-gray-800 border-t-4 border-blue-500">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Resolve Missing Scene Resources
      </h3>
    </div>

    <div class="modal-content px-8 py-4 bg-neutral-gray">
      <div>
        <p>
          <template v-if="missingResources.length > 1">
            {{ missingResources.length }} resources missing.
          </template><template v-else>
            1 missing resource.
          </template>
        </p>
        <p class="text-sm">
          The following texture resources could not be found during scene loading.<br>
          A default fallback texture has been used.
        </p>

        <div class="bg-white my-2 py-2 px-4 text-sm rounded">
          <div
            v-for="row in missingResources"
            class="flex items-center justify-between py-1"
          >
            <span class="font-mono">{{ row }}</span>

            <a
              href="#"
              class="btn icon btn-xs text-center focus:text-gray-100 hover:text-gray-100"
              @click.prevent="onSelect(row)"
            >
              Select Asset
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import MapAssetSelect from '@components/editor/module/inputs/MapAssetSelect'

export default {

  props: {
    gui: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      activeRow: null
    }
  },

  computed: {
    missingResources () {
      return this.gui.model.loader.missing
    }
  },

  methods: {
    onSelect (row) {
      this.activeRow = row
      this.$bus.$emit('overlay:modal', {
        component: MapAssetSelect,
        props: {
          callback: this.updateMap
        }
      })
    },

    updateMap (asset) {
      let url = asset.url
      let key = this.activeRow

      this.gui.model.loader.addToFileMap(this.activeRow, url)

      let type = 'image/jpg'
      if (url.includes('.png')) type = 'image/png' // Gross
      this.$bus.$emit('scene:model:resource-association-updated', { key: key, resource_id: asset.id, type: type })

      // If there are more than 1 missing resources, reopen this modal on callback
    }
  }

}
</script>
