<template>
  <div class="pb-8">
    <div
      class="mx-2"
      :style="explorerStyle"
    >
      <div class="py-2 px-4 py-3 border-b border-gray-200 sm:px-6 sticky top-0 z-40 bg-white">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Select an available asset
        </h3>
        <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          {{ typeSelectNoun }}
        </p>
      </div>

      <div class="-mx-2 -my-1 pt-1 bg-neutral-gray">
        <AssetsExplorer
          v-if="project && activeFolder"
          :assets="assets"
          :project="project"
          :override-select="true"
          :active-folder="activeFolder"
          :action-mode="'update'"
          :restricted-action-types="restrictedActionTypes"
          @select-asset="onSelectAsset"
        />
      </div>
    </div>
  </div>
</template>
<script>

const AssetsExplorer = () => import('@components/assets/assets-explorer.vue')

export default {
  name: 'MapAssetSelect',

  components: {
    AssetsExplorer
  },

  props: ['callback'],

  computed: {

    isSceneModule () {
      return this.type === 'scene'
    },

    restrictedActionTypes () {
      return ['image']
    },

    typeSelectNoun () {
      if (this.isSceneModule) return 'Choose a scene to apply to the sequence.'
      return 'Choose image content to apply to the texture map.'
    },

    explorerStyle () {
      return 'min-height: 50vh'
      // @todo - this should be calculated based on the page height
      // we want to set it explicty to stop any jumps when navigating between folders
    },

    assetId () {
      return this.$route.params.uuid
    },

    asset () {
      return this.$store.getters['assets/getAssetById'](this.assetId)
    },

    projectId () {
      return this.asset.project_id
    },

    assets: function () {
      return this.$store.getters['assets/assets']
    },

    project: function () {
      return this.$store.getters['project/project']
    },

    activeFolder: function () {
      return this.$store.getters['assets/activeSet'](this.projectId)
    }
  },

  methods: {
    onSelectAsset (asset) {
      if (this.restrictedActionTypes.includes(asset.type)) {
        // Get the asset. This might incur an api call to fetch the asset details
        this.$store.dispatch('assets/loadAsset', { id: asset.id })
          .then(item => {
            this.$emit('close')
            this.callback(item)
          })
          .catch((err) => {
            console.log('Failed to load asset :<', err)
          })
      }
    }
  }

}
</script>
